import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import SEO from './seo';
import Styles from '../templates/styles';
import Article from './article';
import PageTitle from './page-title';
import {
  decodeHtml,
  convertPositionNounGender,
  sortPlayers,
  sortCollaborators,
} from '../helpers';
import ConditionalRenderer from './conditionalRenderer';

const PlayersGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 380px));
  grid-gap: 1rem;
  margin: 0;
  padding: 0;
  * {
    margin: 0;
    padding: 0;
  }
  justify-items: center;
  align-items: center;
  justify-content: center;
`;

const GridItem = styled(Link)`
  margin: 0.5rem 0;
  position: relative;
  transition: transform 0.25s ease-in-out;
  transform: scale(1);

  &:hover {
    transform: scale(1.05);
    z-index: 100;
  }
`;

const PlayerInfo = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 35%);
`;

const InfoParagraph = styled.p`
  text-align: center;
  color: white;
  &:first-of-type {
    font-weight: bold;
    font-size: 1.2rem;
  }
  &:last-of-type {
    margin-bottom: 1rem;
  }
`;

const Shirt = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.text_on_primary};
  font-weight: bold;
  font-size: 1.2rem;
  z-index: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Avatar = styled.figure`
  max-width: 425px;
  max-height: 508px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`;

export default function renderer(props) {
  const { title, players, collaborators } = props;

  const players_cards = sortPlayers(players).map((player) => {
    const {
      title: name,
      slug,
      position: { label: position },
      playerData: {
        foto: { sourceUrl: image },
        nome: full_name,
        elenco,
        numeroDaCamiseta,
      },
    } = player;
    return (
      <GridItem
        image={image}
        to={slug ? `/futebol/${slug}` : null}
        as={slug ? null : 'div'}
        key={slug}
      >
        <Avatar>
          <img src={image} alt={decodeHtml(name)} />
        </Avatar>
        <ConditionalRenderer
          condition={numeroDaCamiseta}
          renderOnSuccess={() => <Shirt>{numeroDaCamiseta}</Shirt>}
        />
        <PlayerInfo>
          <InfoParagraph title={full_name}>{decodeHtml(name)}</InfoParagraph>
          <InfoParagraph>
            {convertPositionNounGender(position, elenco)}
          </InfoParagraph>
        </PlayerInfo>
      </GridItem>
    );
  });

  const collaborators_cards = sortCollaborators(collaborators).map(
    (collaborator) => {
      const {
        title: name,
        slug,
        role: { label: department },
        playerData: {
          foto: { sourceUrl: image },
          nome: full_name,
          cargo: role,
        },
      } = collaborator;
      return (
        <GridItem image={image} to={null} as="div" key={slug}>
          <Avatar>
            <img src={image} alt={decodeHtml(name)} />
          </Avatar>
          <PlayerInfo>
            <InfoParagraph title={full_name}>{decodeHtml(name)}</InfoParagraph>
            <InfoParagraph>{role}</InfoParagraph>
            <InfoParagraph>{department}</InfoParagraph>
          </PlayerInfo>
        </GridItem>
      );
    },
  );

  return (
    <>
      <SEO title={title} />
      <Styles />
      <Article>
        <PageTitle>{title}</PageTitle>
        <PlayersGrid>
          {players_cards}
          {collaborators_cards}
        </PlayersGrid>
      </Article>
    </>
  );
}
