import PageRenderer from '../components/team-page-renderer';

export default function ElencoMasculino(props) {
  const {
    pageContext: { title, players, collaborators },
  } = props;

  return PageRenderer({
    players,
    collaborators,
    title,
  });
}
